@import 'styles/mixins.scss';

.index {
  display: flex;
  height: 100vh;
  width: 100%;
  flex-direction: row;

  @include tablet {
    flex-direction: column;
  }
  @include phone {
    flex-direction: column;
  }
  &__left {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 50%;
    @include tablet {
      width: 100%;
      min-height: 70vh;
      position: relative;
    }

    @include phone {
      width: 100%;
      min-height: 70vh;
      position: relative;
    }

    display: flex;
    flex-direction: column;
    justify-content: center;
    .index__logo {
      padding-left: 16px;
      padding-right: 16px;
      transform: translateY(-30px);
      user-select: none;

      &-title {
        padding: 0;
        margin: 0;
        svg {
          height: 130px;
          @include phone {
            height: 85px;
          } 
        }
      }
      &-subtitle {
        padding: 0;
        margin: 0;
        font-weight: 500;
        position: relative;
        a {
          color: white;
          text-decoration: none;
        }
        img {
          position: absolute;
          top: -3px;
          margin-left: 25px;
          @include desktop {
            height: 36px;
            margin-left: 20px;
          }
          @include tablet {
            height: 31px;
            margin-left: 18px;
          }
          @include phone {
            height: 26px;
            margin-left: 10px;
            top: 16px;
          } 
        }

        @include ldesktop {
          font-size: 2rem;
          line-height: 2rem; 
        }
        @include desktop {
          font-size: 1.6rem;
          line-height: 1.6rem; 
        }
        @include tablet {
          font-size: 1.5rem;
          line-height: 1.5rem; 
        }
        @include phone {
          font-size: 1.2rem;
          line-height: 1.2rem;
          display: flex;
          flex-direction: column;
        } 
      }
    }
    .index__info {
      display: flex;
      position: absolute;
      font-size: 1.5rem;
      padding: 0;
      margin: 16px;
      bottom: 0;
      left: 0;
      @include tablet {
        font-size: 1.2rem;
      }
      @include phone {
        font-size: 1rem;
      }
    }
  }
  &__right {
    min-height: 100vh;
    max-height: 100vh;
    margin-left: 50%;

    @include tablet {
      margin-left: 0;
    }
    @include phone {
      margin-left: 0;
    }
   
    .index-main-content {
      height: 100vh;
      width: 100%;
      &__inner {
        overflow: hidden;
        @include ldesktop {
          padding-top: 48px;
        }
        @include desktop {
          padding-top: 48px;
        }
        @include tablet {
          padding-bottom: 48px;
        }
        @include phone {
          padding-bottom: 48px;
        }
       
      }
    }

    @include ldesktop {
      width: 50%;
    }
    @include desktop {
      width: 50%;
    }
    @include tablet {
      width: 100%;
    }
     @include phone {
      width: 100%;
    }
  }
}
