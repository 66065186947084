@import 'styles/mixins.scss';

.footer {
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 24px;
  box-sizing: border-box;
  border-top: 1px solid white;
  a {
    color: white;
    &:hover {
      color: #ff9800
    }
  }
  @include phone {
    flex-direction: column;
  }
  .footer-info {
    width: 50%;
    @include phone {
      width: 100%;
      padding-bottom: 24px;
    }
    svg {
      height: 24px;
      padding-left: 8px;
      padding-right: 8px;
      fill: white;
      &:hover {
        fill: #ff9800;
      }
    }
  }
  .footer-map {
    width: 50%;
    @include phone {
      width: 100%;
    }
    iframe {
      width: 100%;
      height: 100%;
    }
  }
}

.index-main-content {
  .footer {
    border-left: 1px solid white;
    @include tablet {
      border-left: none;
    }
    @include phone {
      border-left: none;
    }
  }
}
