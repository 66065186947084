@mixin phone {
  @media screen and (max-width: 479px) {
    @content;
  }
}

@mixin tablet {
  @media screen and (min-width: 480px) and (max-width: 1023px) {
    @content;
  }
}

@mixin desktop {
  @media screen and (min-width: 1024px) and (max-width: 1199px) {
    @content;
  }
}

@mixin ldesktop {
  @media screen and (min-width: 1200px) {
    @content;
  }
}