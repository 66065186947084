@import 'styles/mixins.scss';

.gallery {
  min-height: 100vh;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  @include ldesktop {
    width: 1024px;
    margin: 24px auto;
  }
  @include desktop {
    width: 960px;
    margin: 24px auto;
  }
  @include tablet {
    margin: 24px;
  }
  @include phone {
    margin: 24px;
  }
  .image-wrapper {
    padding: 24px;
    box-sizing: border-box;
    overflow: 'hidden';
    img {
      width: 100%;
    }
    @include ldesktop {
      max-width: 33%;
      min-width: 33%;
      max-height: 30vw;
      min-height: 245px;
    }
    @include desktop {
      max-width: 33%;
      min-width: 33%;
      max-height: 33vw;
      min-height: 231px;
    }
    @include tablet {
      max-width: 50%;
      min-width: 50%;
      max-height: 50vw;
      padding: 8px;
    }
    @include phone {
      max-width: 100%;
      min-width: 100%;
      max-height: 100vh;
      padding: 24px 0;

    }
  }
}